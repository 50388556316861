/* eslint-disable prettier/prettier */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CFormSelect,
  // CCard,
  // CCardBody,
  // CCardHeader,
  // CCol,
  // CRow,
} from '@coreui/react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'

const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}` } }

export async function LoaderCalculatorUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await axios
      .get(
        process.env.REACT_APP_SERVER_URL + '/api/calculator_price_yurs/' + params.optionId,
        header,
      )
      .then((response) => {
        return response
      })
    return res
  }
  return false
}
// const formReducer = (state, event) => {
//   return {
//     ...state,
//     [event.target.name]: event.target.value
//   }
// }
const calculatorForm = () => {
  const { data } = useLoaderData();
  const navigate = useNavigate();
  
  const [rozpList, SetRozpodilList] = useState([])
  const [name, setName] = useState(data != undefined ? data?.oper?.name : 'ВАТ «Тернопільобленерго»')
  const [period, setPeriod] = useState(data != undefined ? `${data.period.split('_')[0]}-${data.period.split('_')[1]}-01` : '',)
  const [klas, setKlas] = useState(data != undefined ? data.klas : 1)
  const [description, setDescription] = useState(data != undefined ? data.description : '');

  const [price1, setPrice1] = useState(data != undefined ? data.priceOne : 0)
  const [price2, setPrice2] = useState(data != undefined ? data.priceTwo : 0)
  const [price3, setPrice3] = useState(data != undefined ? data.priceThree : 0)
  const [price4, setPrice4] = useState(data != undefined ? data.priceFour : 0)

  const [currentRozp] = rozpList.filter(item => {
    return item.name === name;
  })
  const all = (
    parseFloat(price1) +
    parseFloat(price2) +
    parseFloat(price3) +
    parseFloat(price4)
  ).toFixed(2)

  useEffect(() => {
    let active = false
    if (data === undefined) {
      axios.get(process.env.REACT_APP_SERVER_URL + '/api/oper_rozps?pagination=false', header).then((response) => {
        if (!active) {
          SetRozpodilList(response.data['hydra:member'])
        }
      })
      return () => {
        active = true
      }
    }
  }, [])

  console.log(currentRozp?.["@id"]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const article = {
      period: `${period.split('-')[0]}_${period.split('-')[1]}`,
      oper: currentRozp?.["@id"] || '/api/oper_rozps/7',
      klas: parseInt(klas),
      priceOne: parseFloat(price1),
      priceTwo: parseFloat(price2),
      priceThree: parseFloat(price3),
      priceFour: parseFloat(price4),
      description : description
    }
    if (data != undefined) {
      const response = axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/calculator_price_yurs/' + data.id,
        article,
        header,
      ).then(()=>{navigate('/calculator-your');});
    } else {
      const response = axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/calculator_price_yurs',
        article,
        header,
      ).then(()=>{navigate('/calculator-your');});
    }
  }

  return (
    <>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          {rozpList.length ? (
            <>
              <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
              <CFormSelect
                id="exampleFormControlInput1"
                value={name}
                onChange={(e) => setName(e.target.value)}
              >
                {rozpList.map((rozp) => {
                  return (
                    <option key={rozp.id} value={rozp.name}>
                      {rozp.name}
                    </option>
                  )
                })}
              </CFormSelect>
            </>
          ) : (
            <>
              <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Дата</CFormLabel>
          <CFormInput
            type="date"
            id="exampleFormControlInput2"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Клас напруги</CFormLabel>
          <CFormInput
            type="number"
            id="exampleFormControlInput3"
            min={1}
            max={2}
            value={klas}
            onChange={(e) => setKlas(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInpu4">Cередня закупівельна ціна</CFormLabel>
          <CFormInput
            type="number"
            id="exampleFormControlInput4"
            value={price1}
            onChange={(e) => setPrice1(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">
            Тариф з розподілу електричної енергії
          </CFormLabel>
          <CFormInput
            type="number"
            id="exampleFormControlInput5"
            value={price2}
            onChange={(e) => setPrice2(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Тариф на послуги постачальника</CFormLabel>
          <CFormInput
            type="number"
            id="exampleFormControlInput6"
            value={price3}
            onChange={(e) => setPrice3(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput7">
            Тариф з передачі електричної енергії
          </CFormLabel>
          <CFormInput
            type="number"
            id="exampleFormControlInput7"
            value={price4}
            onChange={(e) => setPrice4(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput8">
            Примітка
          </CFormLabel>
          <CFormInput
            type="text"
            id="exampleFormControlInput8"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput8">Всього</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput8" value={all} disabled />
        </div>
        <CButton type="submit" color="success">
          Зберегти
        </CButton>
      </CForm>
    </>
  )
}

export default calculatorForm
