import React, { useState } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react'
import { DataGrid } from '@mui/x-data-grid'
import { Link, useLoaderData } from 'react-router-dom'
import axios from 'axios'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { confirmAlert } from 'react-confirm-alert'

const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}` } }

const page = 1
const postPerPage = 0
const pageSize = 20

export async function LoaderCalculator({ params }) {
  let res = await axios
    .get(process.env.REACT_APP_SERVER_URL + '/api/calculator_price_yurs?page=' + page, header)
    .then((response) => {
      return response
    })
  return res
}

const Calculator = () => {
  const { data } = useLoaderData()
  const [rows, setRows] = useState(false)
  const [rowCountState, setRowCountState] = React.useState(0)
  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member'])
  }
  const getRowsTable = (p = 1) => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + '/api/calculator_price_yurs?page=' + p, header)
      .then((response) => {
        setRows(response.data['hydra:member'])
      })
  }
  const handlePageChange = (page) => {
    getRowsTable(page + 1)
  }

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = axios
              .delete(
                process.env.REACT_APP_SERVER_URL + '/api/calculator_price_yurs/' + row.id,
                header,
              )
              .then((response) => {
                getRowsTable()
              })
          },
        },
        {
          label: 'Ні',
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'name',
      headerName: 'Оператор системи розподілу',
      sortable: false,
      width: 370,
      renderCell: (params) => {
        // console.log(params)
        return params.row.oper.name
      },
    },
    // { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'klas', headerName: 'Клас напруги', width: 100 },
    { field: 'period', headerName: 'Період', width: 100 },
    { field: 'priceOne', headerName: 'Закупівельна ціна', width: 100 },
    { field: 'priceTwo', headerName: 'Розподіл тариф', width: 100 },
    { field: 'priceThree', headerName: 'Постачальник тариф', width: 100 },
    { field: 'priceFour', headerName: 'Передача тариф', width: 100 },
    {
      field: 'sum',
      headerName: 'Всього',
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.priceOne +
          params.row.priceTwo +
          params.row.priceThree +
          params.row.priceFour
        ).toFixed(2)
      },
      width: 70
    },
    {
      field: 'action',
      headerName: 'Редагувати',
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={'/calculator-your/update/' + params.row.id}>
            <CButton color="dark" variant="outline">
              <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
            </CButton>
          </Link>
        )
      },
      width: 70
    },
    {
      field: 'delete',
      headerName: 'Видалити',
      sortable: false,
      renderCell: (params) => {
        return (
          <CButton
            color="danger"
            variant="outline"
            onClick={() => {
              Deleted(params.row)
            }}
          >
            <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
          </CButton>
        )
      },
      width: 70
    },
  ]

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Калькулятор</strong> <small>*</small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to="/calculator-your/create">
                <CButton color="secondary">
                  <CIcon icon={cilPlus} customClassName="nav-icon" height={15} />
                  Створити
                </CButton>
              </Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default Calculator
