import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton
} from '@coreui/react';
import {Link, useLoaderData, useNavigate} from 'react-router-dom';
import axios from "axios";

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };

export async function LoaderNewsUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/news/' + params.newsId, header).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const newsForm = () => {
  const {data}  = useLoaderData();
  const navigate = useNavigate();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined)? data.newsShot : '');
  const [newsDate, setNewsDate] = useState((data != undefined)? new Date(data.date).toISOString() : new Date().toISOString());
  const [imgLink, setimgLink] = useState((data != undefined)? data.imgLink : '');
  const [active, setActive] = useState((data != undefined)? data.active : 'on');

  const editorRef = useRef(null);
  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const article = {
        name: name,
        active: active,
        date: newsDate,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
        imgLink: imgLink, 
      };
      if (data != undefined){
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/news/' + data.id , article, header).then(()=>{navigate('/news');});
      }else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/news', article, header).then(()=>{navigate('/news');});
      }
    }
  }

  console.log(data);

    return (
      <>
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Дата створення </CFormLabel>
            <CFormInput type="datetime-local" id="exampleFormControlInput1" value={newsDate.split('.')[0]} onChange={(e) => setNewsDate(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput3">Головне зображення</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput3" value={imgLink} onChange={(e) => setimgLink(e.target.value)} />
            <Link to="/media" target='_blanck'>перейти до медіа</Link>
          </div>
          <div className="mb-3" style={{textAlign: "right"}}>
            <input class="form-check-input" type="checkbox" value={active} onChange={(e) => setActive(e.target.value === 'on' ? 'of' : 'on')} id="flexCheckDefault" checked = {active === 'of'}/>
            <label class="form-check-label" for="flexCheckDefault">&nbsp;Приховати новину</label>
          </div>
          <div className="mb-3">
            <Editor
              apiKey={process.env.REACT_APP_API_TINY}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={(data != undefined)? data.newsFull : ''}
              init={{
                height: 500,
                menubar: 'edit view insert format tools',
                plugins: [
                  'advlist','autolink',
                  'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                  'insertdatetime','media','table', 'help', 'code'
               ],
                toolbar: 'undo redo | fontfamily fontsize | bold italic backcolor | ' +
                  'alignleft aligncenter alignright alignjustify | ' +
                  'bullist numlist checklist outdent indent | removeformat | link media a11ycheck table help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
          </div>
          <CButton color="secondary" onClick={()=>{navigate('/news')}} style={{marginRight:"10px"}}>Назад</CButton>
          <CButton type="submit" color="success">Зберегти</CButton>
        </CForm>
      </>
    );
};

export default newsForm;
