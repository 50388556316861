import React, { useRef, useState,  useEffect } from 'react';
//import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };
const page = 1;

export async function LoaderCalculatorPobutUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts/' + params.optionId, header).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}


const calculatorPobutForm = () => {
  const pathname = window.location.pathname.split('/');
  const {data}  = useLoaderData();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [orders, setOrders] = useState((data != undefined)? data.orders : 1000);

  const [dev_rows, setDevRows] = useState(false);
  if(!dev_rows){
    if (data != undefined){
      setDevRows(data['lepCalculatorPobuts']);
    }
  }

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts/' + row.id, header).then((response) => {
              getRowsTable();
            });
            if (pathname[3] > 0){
              setTimeout(() => {
                window.location = '/calculator-pobut/update/' + pathname[3];
                }, 200);
            }
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
    
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: "name",
      headerName: "Пристрій",
      sortable: false,
      width: 400,
      renderCell: (params) => {
        console.log(params)
        return params.row.name;
      }
    },
    { field: 'watt', headerName: 'Потужність', width: 100 },
    { field: 'maxCount', headerName: 'Максимальна кількість', width: 200 },
    
    
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={'/calculator-pobut/' + data.id + '/update/' + params.row.id}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
            Deleted(params.row)
          }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];
  
  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  }
  
  const handleSubmit = event => {
      event.preventDefault();
      const article = {
        name: name,
        maxCount: 0,
        watt: 0,
        hour: 0,
        orders: orders
      };
      
      if (data != undefined){
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts/' + data.id , article, header);
      }else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts', article, header);
      }
      setTimeout(() => {
        window.location = '/calculator-pobut';
        }, 200);
  }

  
   let new_dev_link = "/calculator-pobut/create";
   if (data != undefined) {
       new_dev_link = "/calculator-pobut/" + data.id+ "/create";
   }
         
    return (
      <>
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput">Назва типу приладів</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
         
          <CButton type="submit" color="success">Зберегти</CButton>
        </CForm>
        <div style={{ width: '100%', marginTop: '40px'}}>
          <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  
                  <CCardBody>
                    <p className="text-medium-emphasis small">
                      <Link to={new_dev_link}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height="15" />Додати прилад</CButton></Link>
                    </p>
                    <div style={{ height: 400, width: '100%'}}>

                    <DataGrid
                      rows={dev_rows}
                      columns={columns}
                      pageSize={30}
                      rowsPerPageOptions={[30]}
                      pagination
                      //rowCount={rowCountState}
                      paginationMode="server"
                      onPageChange={handlePageChange}
                    />
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </div>
      </>
    );
};

export default calculatorPobutForm;
