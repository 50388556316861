import React, { useRef, useState,  useEffect } from 'react';
//import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };
const page = 1;


export async function LoaderCalculatorPobutDeviceUpdate({ params }) {
    if(Object.keys(params).length != 1) {
        let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts/' + params.devId, header).then((response) => {
        return response;
        });
        return res;
    }
    return  false;
}



const calculatorPobutDeviceForm = () => {
    const pathname = window.location.pathname.split('/');
    const {data}  = useLoaderData();
    const [name, setName] = useState((data != undefined)? data.name : '');
    const [maxCount, setMaxCount] = useState((data != undefined)? data.maxCount : 0);
    const [watt, setWatt] = useState((data != undefined)? data.watt : 0);

    const [orders, setOrders] = useState((data != undefined)? data.orders : 2000);

    
    const handleSubmit = event => {
        event.preventDefault();
        const article = {
            name: name,
            parent: '/api/lep_calculator_pobuts/' + pathname[2],
            maxCount: Number(maxCount),
            watt: Number(watt),
            hour: 0,
            orders: orders
        };

            
      if (data != undefined){
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts/' + data.id , article, header);
      }else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/lep_calculator_pobuts', article, header);
      }
      setTimeout(() => {
            window.location = '/calculator-pobut/update/' + pathname[2];
        }, 200);
  }

            
    return (
      <>
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Прилад</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput2">Потужність</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput2" value={watt} onChange={(e) => setWatt(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput3">Максимальна кількість</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput3" value={maxCount} onChange={(e) => setMaxCount(e.target.value)} />
          </div>
          
         
          <CButton type="submit" color="success">Зберегти</CButton>
        </CForm>
        
      </>
    );
};

export default calculatorPobutDeviceForm;
