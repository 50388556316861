import React, { useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CFormInput
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderPages({ params }) {
  let res = await  axios.get(process.env.REACT_APP_SERVER_URL + '/api/pages?page=' + page, header).then((response) => {
    return response;
  });
  return res;
}

const Pages = () => {
  const {data}  = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  if(!rows){
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }
  const getRowsTable = (p=1) => {
    axios.get(process.env.REACT_APP_SERVER_URL + '/api/pages?page=' + p, header).then((response) => {
        setRows(response.data['hydra:member']);
      });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  }

  function handleSearch (event) {
    axios.get(process.env.REACT_APP_SERVER_URL + '/api/pages?page=1&name=' + event.target.value, header).then((response) => {
      setRows(response.data['hydra:member']);
    });
  }

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/pages/' + row.id, header).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }

  function changeActive(row) {
    console.log(row);
    confirmAlert({
      title: 'Ви впевнені що хочете змінити відображення сторінки ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const article = {
              name: row.name,
              synonym: row.synonym,
              content: row.content,
              active: row.active === 'on' ? 'off' : 'on',
              orders: 0,
              images: row.mainImg,
            };
            axios.put(process.env.REACT_APP_SERVER_URL + '/api/pages/' + row.id, article, header).then((response)=>{
              getRowsTable();
            })
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 300 },
    { field: 'newsShot', headerName: 'Опис', width: 100 },
    // {
    //   field: 'created_at',
    //   headerName: 'Дата',
    //   type: 'date',
    //   width: 160,
    // },
    { field: 'synonym', headerName: 'Посилання', width: 200 },
    {
      field: 'active',
      headerName: 'Приховано',
      sortable: false,
      renderCell: (params) => {
        return <input class="form-check-input" type="checkbox" id="flexCheckDefault" value={params.row.active} checked = {params.row.active === 'off'} onChange={()=>{
          changeActive(params.row)
        }}/>;
      }
    },
    {
      field: "action",
      headerName: "Редагувати",
      sortable: false,
      renderCell: (params) => {
        return <Link to={'/site-pages/update/' + params.row.id}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
            Deleted(params.row)
          }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];


  return (
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Сторінки</strong> <small>*</small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to="/site-pages/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <CFormInput type="search" placeholder='Пошук по назві' onChange={handleSearch} style={{ marginBottom: "10px" }}/>
              <div style={{ height: 400, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
};

export default Pages;
