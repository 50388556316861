import React from 'react';

import News, { LoaderNews } from './views/news/News';
import NewsForm, { LoaderNewsUpdate } from './views/news/NewsForm';

import Banners, { LoaderBanners } from './views/banners/Banners';
import BannersForm, { LoaderBannersUpdate } from './views/banners/BannersForm';

import Pages, { LoaderPages } from './views/site-page/Pages';
import PagesForm, { LoaderPagesUpdate } from './views/site-page/PagesForm';

import Blog, { LoaderBlog } from './views/blog/Blog';
import BlogForm, { LoaderBlogUpdate } from './views/blog/BlogForm';

import Menu, { LoaderMenu } from './views/menu/Menu';
import MenuForm, { LoaderMenuUpdate } from './views/menu/MenuForm';

import Media, { LoaderMedia } from './views/media/Media';

import Options, { LoaderOptions } from './views/options/Options';
import OptionForm, { LoaderOptionUpdate } from './views/options/OptionForm';

import CreatedForms, { LoaderCreatedForms } from './views/builder-forms/CreatedForms';
import BuilderForms, { LoaderBuilderForms } from './views/builder-forms/BuilderForms';

import FormsAnswer, { LoaderFormsAnswer } from './views/form-answer/FormsAnswer';
import FormAnswerItem, { LoaderFormAnswerItem } from './views/form-answer/FormAnswerItem';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

import Calculator, { LoaderCalculator } from './views/calculator-your/Calculator';
import CalculatorForm, { LoaderCalculatorUpdate } from './views/calculator-your/CalculatorForm';

import CalculatorPobut, { LoaderCalculatorPobut } from './views/calculator-pobut/CalculatorPobut';
import CalculatorPobutForm, { LoaderCalculatorPobutUpdate } from './views/calculator-pobut/CalculatorPobutForm';
import CalculatorPobutDeviceForm, { LoaderCalculatorPobutDeviceUpdate } from './views/calculator-pobut/CalculatorPobutDeviceForm';



// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))
const Login = React.lazy(() => import('./views/pages/login/Login'))


/** ASD ROUTERS */
// const NewsForm = React.lazy(() => import('./views/news/NewsForm'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: <Dashboard /> },
  { path: '/theme', name: 'Theme', element: Colors, exact: <true /> },
  { path: '/theme/colors', name: 'Colors', element: <Colors /> },
  { path: '/theme/typography', name: 'Typography', element: <Typography /> },
  { path: '/base', name: 'Base', element: Cards, exact: <true /> },
  { path: '/base/accordion', name: 'Accordion', element: <Accordion /> },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: <Breadcrumbs /> },
  { path: '/base/cards', name: 'Cards', element: <Cards /> },
  { path: '/base/carousels', name: 'Carousel', element: <Carousels /> },
  { path: '/base/collapses', name: 'Collapse', element: <Collapses /> },
  { path: '/base/list-groups', name: 'List Groups', element: <ListGroups /> },
  { path: '/base/navs', name: 'Navs', element: <Navs /> },
  { path: '/base/paginations', name: 'Paginations', element: <Paginations /> },
  { path: '/base/placeholders', name: 'Placeholders', element: <Placeholders /> },
  { path: '/base/popovers', name: 'Popovers', element: <Popovers /> },
  { path: '/base/progress', name: 'Progress', element: <Progress /> },
  { path: '/base/spinners', name: 'Spinners', element: <Spinners /> },
  { path: '/base/tables', name: 'Tables', element: <Tables /> },
  { path: '/base/tooltips', name: 'Tooltips', element: <Tooltips /> },
  { path: '/buttons', name: 'Buttons', element: Buttons, exact: <true /> },
  { path: '/buttons/buttons', name: 'Buttons', element: <Buttons /> },
  { path: '/buttons/dropdowns', name: 'Dropdowns', element: <Dropdowns /> },
  { path: '/buttons/button-groups', name: 'Button Groups', element: <ButtonGroups /> },
  { path: '/charts', name: 'Charts', element: <Charts /> },
  { path: '/forms', name: 'Forms', element: FormControl, exact: <true /> },
  { path: '/forms/form-control', name: 'Form Control', element: <FormControl /> },
  { path: '/forms/select', name: 'Select', element: <Select /> },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: <ChecksRadios /> },
  { path: '/forms/range', name: 'Range', element: <Range /> },
  { path: '/forms/input-group', name: 'Input Group', element: <InputGroup /> },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: <FloatingLabels /> },
  { path: '/forms/layout', name: 'Layout', element: <Layout /> },
  { path: '/forms/validation', name: 'Validation', element: <Validation /> },
  { path: '/icons', exact: true, name: 'Icons', element: <CoreUIIcons /> },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: <CoreUIIcons /> },
  { path: '/icons/flags', name: 'Flags', element: <Flags /> },
  { path: '/icons/brands', name: 'Brands', element: <Brands /> },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: <true /> },
  { path: '/notifications/alerts', name: 'Alerts', element: <Alerts /> },
  { path: '/notifications/badges', name: 'Badges', element: <Badges /> },
  { path: '/notifications/modals', name: 'Modals', element: <Modals /> },
  { path: '/notifications/toasts', name: 'Toasts', element: <Toasts /> },
  { path: '/widgets', name: 'Widgets', element: <Widgets /> },

  { path: '/news', name: 'Новини', element: <News />, loader: LoaderNews },
  { path: '/news/create', name: 'Створити новини', element: <NewsForm />, loader: LoaderNewsUpdate },
  { path: '/news/update/:newsId', name: 'Редагувати новини', element: <NewsForm />, loader: LoaderNewsUpdate },

  { path: '/banners', name: 'Банери', element: <Banners />, loader: LoaderBanners },
  { path: '/banners/create', name: 'Створити банер', element: <BannersForm />, loader: LoaderBannersUpdate },
  { path: '/banners/update/:newsId', name: 'Редагувати банер', element: <BannersForm />, loader: LoaderBannersUpdate },

  { path: '/site-pages', name: 'Сторінки', element: <Pages />, loader: LoaderPages },
  { path: '/site-pages/create', name: 'Створити сторінку', element: <PagesForm />, loader: LoaderPagesUpdate },
  { path: '/site-pages/update/:newsId', name: 'Редагувати сторінку', element: <PagesForm />, loader: LoaderPagesUpdate },

  { path: '/blog', name: 'Блог', element: <Blog />, loader: LoaderBlog },
  { path: '/blog/create', name: 'Створити блог', element: <BlogForm />, loader: LoaderBlogUpdate },
  { path: '/blog/update/:newsId', name: 'Редагувати блог', element: <BlogForm />, loader: LoaderBlogUpdate },

  { path: '/menu', name: 'Меню', element: <Menu />, loader: LoaderMenu },
  { path: '/menu/create', name: 'Створити меню', element: <MenuForm />, loader: LoaderMenuUpdate },
  { path: '/menu/update/:menuId', name: 'Редагувати меню', element: <MenuForm />, loader: LoaderMenuUpdate },
  { path: '/menu/children/:child_id', name: 'Редагувати меню', element: <MenuForm />, loader: LoaderMenuUpdate },


  { path: '/media', name: 'Медіа', element: <Media />, loader: LoaderMedia },

  { path: '/options', name: 'Налаштування', element: <Options />, loader: LoaderOptions },
  { path: '/options/create', name: 'Створити Налаштування', element: <OptionForm />, loader: LoaderOptionUpdate },
  { path: '/options/update/:optionId', name: 'Редагувати Налаштування', element: <OptionForm />, loader: LoaderOptionUpdate },


  { path: '/calculator-your', name: 'Калькулятор', element: <Calculator />, loader: LoaderCalculator },
  { path: '/calculator-your/create', name: 'Калькулятор створити', element: <CalculatorForm />, loader: LoaderCalculatorUpdate },
  { path: '/calculator-your/update/:optionId', name: 'Калькулятор Налаштування', element: <CalculatorForm />, loader: LoaderCalculatorUpdate },

  { path: '/calculator-pobut', name: 'Калькулятор Побут', element: <CalculatorPobut />, loader: LoaderCalculatorPobut },
  { path: '/calculator-pobut/create', name: 'Калькулятор Побут Створити', element: <CalculatorPobutForm />, loader: LoaderCalculatorPobutUpdate },
  { path: '/calculator-pobut/update/:optionId', name: 'Калькулятор Побут Налаштування', element: <CalculatorPobutForm />, loader: LoaderCalculatorPobutUpdate },
  { path: '/calculator-pobut/:optionId/create', name: 'Калькулятор Побут Додати прилад ', element: <CalculatorPobutDeviceForm />, loader: LoaderCalculatorPobutDeviceUpdate },
  { path: '/calculator-pobut/:optionId/update/:devId', name: 'Калькулятор Побут Налаштування приладу', element: <CalculatorPobutDeviceForm />, loader: LoaderCalculatorPobutDeviceUpdate },


  { path: '/builder-forms', name: 'Форми', element: <CreatedForms />, loader: LoaderCreatedForms },
  { path: '/builder-forms/create', name: 'Створити форму', element: <BuilderForms />, loader: LoaderBuilderForms },
  { path: '/builder-forms/update/:pid', name: 'Редагувати форму', element: <BuilderForms />, loader: LoaderBuilderForms },

  { path: '/form-answer', name: 'Форми/Відповіді', element: <FormsAnswer />, loader: LoaderFormsAnswer },
  { path: '/form-answer/review/:pid', name: 'Відповідь', element: <FormAnswerItem />, loader: LoaderFormAnswerItem },

  { path: '/login', name: 'Логін', element: <Login /> },
]

export default routes
